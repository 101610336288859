import { defineStore } from 'pinia'

export const usePopsEventsStore = defineStore({
  id: 'pops-events',
  state() {
    return {
      events: [],
      recentlyDeletedEvents: [],
    }
  },
  actions: {
    async fetchPopEvents() {
      try {
        const response = await this.$nuxt.$axios.get(`${process.env.API_V1_URL}/pops/events`)

        this.events = response.data.data
      } catch (error) {
        this.$nuxt.$toasts.add({
          type: 'error',
          title: 'Could not fetch pop events!',
        });
      }
    },

    async deletePopEvent(id) {
      try {
        await this.$nuxt.$axios.$post(`site_events/delete`, { id: id })

        this.$nuxt.$toasts.add({
          type: 'success',
          title: 'Pop Deleted!',
          subtitle: 'Congrats! Your pop was deleted successfully.'
        })
        this.recentlyDeletedEvents.push(id)
      } catch (e) {
        this.$nuxt.$toasts.add({
          type: 'error',
          title: 'Pop delete',
          subtitle: e,
          e
        })
      }
    },
  }
});
